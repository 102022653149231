'use strict'
document.addEventListener('DOMContentLoaded', function () {


    const switcher_to_grid_button = `[data-list-view="init"]`
    const switcher_to_list_button = `[data-grid-view="init"]`
    const has_switcher_buttons = $(switcher_to_grid_button).add(switcher_to_list_button).length === 2

    if (has_switcher_buttons) {

        const localStorage_display_mode = localStorage.getItem('display')

        const switcher_to_list = () => {
            $('#content .product-grid > .clearfix').remove()
            $('#content .row > .product-grid').attr('class', 'product_item product-layout product-list col-xs-12')
            $(switcher_to_grid_button).removeClass('active')
            $(switcher_to_list_button).addClass('active')
            $('.product-grid').css('height', 'auto')
        }
        const switcher_to_grid = () => {
            const cols = $('#column-right, #column-left').length

            if (cols === 2) {
                $('#content .product-list').attr('class', 'product-layout product-grid col-lg-6 col-md-6 col-sm-12 col-xs-12')
            } else if (cols === 1) {
                $('#content .product-list').attr('class', 'product_item product-layout product-grid col-lg-4 col-md-4 col-sm-4 col-xs-6')
            } else {
                $('#content .product-list').attr('class', 'product-layout product-grid col-lg-3 col-md-4 col-sm-6 col-xs-12')
            }

            $(switcher_to_list_button).removeClass('active')
            $(switcher_to_grid_button).addClass('active')
        }

        if (localStorage_display_mode === 'list') {
            switcher_to_list()
        } else if (localStorage_display_mode === 'grid') {
            switcher_to_grid()
        }


        let screen_width = $(window).width() - 1
        $(window).resize(function () {
            const can_auto_switch = localStorage.getItem('display') === null
            const { autoActivateOn } = $(switcher_to_list_button).data()
            const now_screen_mobile_size = window.matchMedia(`(max-width: ${ autoActivateOn }.7px)`).matches

            if ($(this).width() !== screen_width) {

                screen_width = $(this).width()

                if (can_auto_switch && now_screen_mobile_size) {
                    switcher_to_list()
                } else if (can_auto_switch && !now_screen_mobile_size) {
                    switcher_to_grid()
                }
            }
        })
        $(window).trigger('resize')

        $(document)
            .on('click', switcher_to_list_button, function (e) {
                e.preventDefault()
                switcher_to_list()
                localStorage.setItem('display', 'list')
            })
            .on('click', switcher_to_grid_button, function (e) {
                e.preventDefault()
                switcher_to_grid()
                localStorage.setItem('display', 'grid')
            })

    }

    // dimensional grid

    const dimensional_btn_toggle = '[data-dimensional-btn="toggle"]'
    const dimensional_toggle_item = '[data-dimensional-toggle="item"]'



    $(document).on('click', dimensional_btn_toggle, function (e) {
        e.preventDefault()

        const toggle_status = $(this).hasClass('is_open')

        if (toggle_status) {
            $(dimensional_toggle_item).stop(true).slideUp(200,() => {
                console.log(`toggle_item - close now`)
                $(dimensional_btn_toggle).removeClass('is_open')
            })
        } else {
            $(dimensional_toggle_item).stop(true).slideDown(250, () => {
                console.log(`toggle_item - open now`)
                $(dimensional_btn_toggle).addClass('is_open')
            })
        }



    })
    let slideshow = null;
    $('[data-slider*="slideshow-"]').each(function () {
        const _this = `[data-slider="${ this.dataset.slider }"]`

        slideshow = new Swiper(this, {
            slidesPerView: 'auto',
            loop: true,
            speed: 500,
            observer: true,
            observeParents: true,
            autoplay: {
                delay: parseInt($(`[data-slider="${ this.dataset.slider }"]`).data('autoplay-delay')) * 1000 || 30000,
                disableOnInteraction: false,
            },
            // navigation: {
            //     nextEl: `${ _this } ~ .y-video-slider__navigation .y-video-slider__next`,
            //     prevEl: `${ _this } ~ .y-video-slider__navigation .y-video-slider__prev`,
            // },

            on: {
                init() {
                    setTimeout(() => $(_this).parents('.main-slider').find('#spinner').fadeOut('slow'),2000)

                }
            }
        })
    })


})
